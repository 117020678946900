export const enum BillingPlanName {
  Developer = 'Developer',
  StartUp = 'Start-Up',
  Enterprise = 'Enterprise',
}

export const BillingPlanLimits = {
  Development: {
    [BillingPlanName.Developer]: {
      totalPortalWallets: 50,
      mpcOperations: 100,
    },
    [BillingPlanName.StartUp]: {
      totalPortalWallets: 100,
      mpcOperations: 250,
    },
    [BillingPlanName.Enterprise]: {
      totalPortalWallets: Infinity,
      mpcOperations: Infinity,
    },
  },
  Production: {
    [BillingPlanName.Developer]: {
      totalPortalWallets: 0,
      mpcOperations: 0,
    },
    [BillingPlanName.StartUp]: {
      totalPortalWallets: 1000,
      mpcOperations: 1000,
    },
    [BillingPlanName.Enterprise]: {
      totalPortalWallets: Infinity,
      mpcOperations: Infinity,
    },
  },
}
