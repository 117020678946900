import {
  Activity,
  Cog,
  House,
  ShieldAlert,
  Wallet as WalletIcon,
} from 'lucide-react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react'

import Dropdown from '@components/core/forms/dropdown'
import { PortalIcon } from '@components/core/icons'
import ArrowTopIcon from '@components/core/icons/arrowTop'
import BurgerIcon from '@components/core/icons/burger'
import {
  SidebarButton,
  SidebarFooter,
  SidebarFooterLabel,
  SidebarFooterValue,
  SidebarLinkList,
  SidebarLinkListItem,
  SidebarLinks,
  SidebarLinksBox,
  SidebarTitle,
  SidebarWrapper,
} from '@components/navigation/style'
import { useEnvironment } from '@context/environment'
import { useUser } from '@context/user'
import { NavItemClick } from '@lib/analytics'
import api from '@lib/api'
import { BillingPlanName } from '@lib/billing'
import { removeAllCookies, setEnvironmentIdCookie } from '@lib/cookies'

import { SettingsMenuItems } from './settings-navigation'

interface NavigationComponentProps {
  activeMenu: boolean
  toggleActiveMenu: Dispatch<SetStateAction<boolean>>
}

const NavigationComponent: FunctionComponent<NavigationComponentProps> = ({
  activeMenu,
  toggleActiveMenu,
}) => {
  // Derive hooks.
  const router = useRouter()
  const { environment, setEnvironment } = useEnvironment()
  const { user } = useUser()

  // Derive refs.
  const sidebarLinksRef = useRef<HTMLUListElement>(null)

  // Derive state.
  const [settingsDropdownExpanded, setSettingsDropdownExpanded] =
    useState<boolean>(router.asPath.includes('/settings'))

  const scrollToElement = (e: Event) => {
    const target = e.target as HTMLAnchorElement
    const { href: url } = target

    if (url.includes('#') && url.includes(router.asPath)) {
      e.preventDefault()

      const anchor = `${url.split(`#`).pop()}`
      const element = document.getElementById(anchor)

      window.scrollTo({
        top: element.offsetTop - 100,
        behavior: 'smooth',
      })

      return false
    } else if (url.includes(router.asPath)) {
      e.preventDefault()

      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })

      return false
    }
  }

  const onLogout = async () => {
    // Call the logout endpoint and remove cookie.
    try {
      await api.auth.logout()
    } catch (error) {
      console.error('Error logging out but removing cookie anyway', error)
    } finally {
      removeAllCookies()
    }

    // Track the event.
    analytics.track(NavItemClick, {
      environmentId: environment.id,
      environmentName: environment.name,
      path: '/logout',
    })

    // Navigate to the login page.
    await router.push('/login')
  }

  const onEnvironmentChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target

    const environment = user.custodian.environments.find(
      (env) => env.id === value,
    )

    if (environment) {
      // Update the environment context.
      setEnvironment(environment)

      // Update the environment cookie.
      setEnvironmentIdCookie(environment.id)
    }
  }

  // Handle nav item clicks.
  const onNavItemClick = (path: string) => () => {
    // Track the event.
    analytics.track(NavItemClick, {
      environmentId: environment.id,
      environmentName: environment.name,
      path,
    })
  }

  // Add scroll behavior for local page links
  useEffect(() => {
    if (sidebarLinksRef.current) {
      const links = sidebarLinksRef.current?.getElementsByTagName('a')
      if (links) {
        Array.from(links).forEach((link: HTMLAnchorElement) => {
          link.addEventListener('click', scrollToElement)
        })
      }

      // Cleanup event listeners on component unmount
      return () => {
        if (links) {
          Array.from(links).forEach((link: HTMLAnchorElement) => {
            link.removeEventListener('click', scrollToElement)
          })
        }
      }
    }
  }, [sidebarLinksRef])

  return (
    <SidebarWrapper className="sidebar-wrapper">
      <SidebarTitle>
        <PortalIcon id="logo" />
        <SidebarButton
          onClick={() => toggleActiveMenu(!activeMenu)}
          className="sidebar-button"
        >
          <BurgerIcon className="burger" />
        </SidebarButton>
      </SidebarTitle>

      <SidebarLinksBox className="sidebar-links-box">
        <SidebarLinks>
          <SidebarLinkList>
            <SidebarLinkListItem active={router.route === '/dashboard'}>
              <Link passHref legacyBehavior href="/dashboard">
                <a id="nav-item-home" onClick={onNavItemClick('/dashboard')}>
                  <House size={16} /> Home
                </a>
              </Link>
            </SidebarLinkListItem>
            <SidebarLinkListItem active={router.route === '/firewall'}>
              <Link passHref legacyBehavior href="/firewall">
                <a id="nav-item-firewall" onClick={onNavItemClick('/firewall')}>
                  <ShieldAlert size={16} /> Firewall
                </a>
              </Link>
            </SidebarLinkListItem>
            <SidebarLinkListItem active={router.route === '/wallets'}>
              <Link passHref legacyBehavior href="/wallets">
                <a id="nav-item-wallets" onClick={onNavItemClick('/wallets')}>
                  <Activity size={16} /> Wallet Actions
                </a>
              </Link>
            </SidebarLinkListItem>
            <SidebarLinkListItem active={router.route === '/wallet'}>
              <Link passHref legacyBehavior href="/wallet">
                <a id="nav-item-wallet" onClick={onNavItemClick('/wallet')}>
                  <WalletIcon size={16} /> Manage Wallet
                </a>
              </Link>
            </SidebarLinkListItem>
            <SidebarLinkListItem>
              <button
                id="nav-item-settings"
                className={`pr${settingsDropdownExpanded ? ' active' : ''}${
                  router.route.includes('/settings') ? ' highlighted' : ''
                }`}
                onClick={() =>
                  setSettingsDropdownExpanded(!settingsDropdownExpanded)
                }
              >
                <Cog size={16} /> Settings <ArrowTopIcon className="arrow" />
              </button>
              {settingsDropdownExpanded && (
                <SidebarLinkList className="sub-list">
                  {SettingsMenuItems.map((menuItem) => {
                    // Hide the account abstraction menu item if it's not enabled.
                    if (
                      menuItem.id === 'aa' &&
                      !user?.custodian?.isAccountAbstractionEnabled
                    ) {
                      return null
                    }

                    // Hide the webhook menu item if backup with portal is enabled.
                    if (
                      menuItem.id === 'webhook' &&
                      environment?.backupWithPortalEnabled
                    ) {
                      return null
                    }

                    if (
                      menuItem.id === 'billing' &&
                      user?.custodian?.billingPlan?.name ===
                        BillingPlanName.Enterprise
                    ) {
                      return null
                    }

                    if (
                      menuItem.id === 'usage' &&
                      user?.custodian?.billingPlan?.name ===
                        BillingPlanName.Enterprise
                    ) {
                      return null
                    }

                    return (
                      <SidebarLinkListItem
                        className={
                          router.asPath === `/settings#${menuItem.id}`
                            ? 'active'
                            : ''
                        }
                        key={menuItem.id}
                      >
                        <Link
                          href={`/settings#${menuItem.id}`}
                          onClick={onNavItemClick(`/settings#${menuItem.id}`)}
                        >
                          {menuItem.title}
                        </Link>
                      </SidebarLinkListItem>
                    )
                  })}
                  <SidebarLinkListItem>
                    <button
                      className="logout"
                      id="nav-item-logout"
                      onClick={onLogout}
                    >
                      Sign Out
                    </button>
                  </SidebarLinkListItem>
                </SidebarLinkList>
              )}
            </SidebarLinkListItem>
          </SidebarLinkList>
        </SidebarLinks>

        <SidebarFooter>
          <SidebarFooterLabel>Environment</SidebarFooterLabel>
          <SidebarFooterValue>
            <Dropdown
              id="nav-environment-dropdown"
              options={
                user?.custodian?.environments?.map((env) => ({
                  label: env.name,
                  value: env.id,
                  disabled:
                    user?.custodian?.billingPlan?.name ===
                      BillingPlanName.Developer && env.name === 'Production',
                })) || []
              }
              onChange={onEnvironmentChange}
              value={environment?.id}
            />
          </SidebarFooterValue>
        </SidebarFooter>
      </SidebarLinksBox>
    </SidebarWrapper>
  )
}

export default NavigationComponent
