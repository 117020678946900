import axios from 'axios'

import {
  apiWrapper,
  buildConnectApiUrl,
  requestConfig,
} from '@lib/api/requests'

export const create = async (environmentId: string): Promise<CreatedClient> => {
  const { data } = await apiWrapper(
    axios.post<CreatedClient>(
      buildConnectApiUrl(
        `/app/custodian/environments/${environmentId}/clients`,
      ),
      {},
      requestConfig,
    ),
  )

  return data
}

export const fund = async (
  environmentId: string,
  reqData: FundClientRequest,
): Promise<FundedClient> => {
  const { data } = await apiWrapper(
    axios.post<FundedClient>(
      buildConnectApiUrl(
        `/app/custodian/environments/${environmentId}/clients/fund`,
      ),
      reqData,
      requestConfig,
    ),
  )

  return data
}

export default {
  create,
  fund,
}
