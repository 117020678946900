import moment from 'moment'
import { FunctionComponent } from 'react'

import {
  ListItem,
  ListItemCircle,
  ListItemLabel,
  ListItemValue,
  ListItems,
} from './style'

type GraphTooltipPayload = {
  color: string
  dataKey: string
  name: string
  payload: { name: string }
  value: number
}

type GraphTooltipProps = {
  active: boolean
  label: string
  payload: GraphTooltipPayload[]
}

const GraphTooltip: FunctionComponent = ({
  active,
  label,
  payload,
}: GraphTooltipProps) => {
  if (!active) {
    return null
  }

  return (
    <ListItems>
      {payload?.reverse()?.map((item) => (
        <ListItem key={item.dataKey}>
          <ListItemCircle style={{ background: item.color }} />
          <ListItemValue>{item.value.toLocaleString()}</ListItemValue>
          <ListItemLabel>{label}</ListItemLabel>
        </ListItem>
      ))}
    </ListItems>
  )
}

export default GraphTooltip
