import axios from 'axios'

import {
  apiWrapper,
  buildConnectApiUrl,
  requestConfig,
} from '@lib/api/requests'

export const getCurrentUsage = async (
  environmentId: string,
  allTimeMpcOperations: boolean,
): Promise<CurrentUsage> => {
  const response = await apiWrapper(
    axios.get(
      buildConnectApiUrl(
        `/app/custodian/environments/${environmentId}/usage/current?allTimeMpcOperations=${allTimeMpcOperations}`,
      ),
      requestConfig,
    ),
  )

  return response.data as CurrentUsage
}

export const getHistoricalUsage = async (
  environmentId: string,
  queryParams: Record<string, any>,
): Promise<HistoricalUsage[]> => {
  const response = await apiWrapper(
    axios.get(
      buildConnectApiUrl(
        `/app/custodian/environments/${environmentId}/usage/historical`,
        queryParams,
      ),
      requestConfig,
    ),
  )

  return response.data as HistoricalUsage[]
}

export default {
  getCurrentUsage,
  getHistoricalUsage,
}
