import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

export const InfoIconContainer = styled.div`
  display: inline-block;
  margin-left: 2px;
  position: relative;
`

export const InfoIconWrapper = styled.div`
  cursor: help;
`

export const Tooltip = styled.div`
  animation: ${fadeIn} 0.2s ease-in-out;
  background-color: ${({ theme }) =>
    theme.isDark ? theme.colors.backgrounds.greyLight : theme.colors.white};
  border-radius: ${({ theme }) => theme.borderRadius.default};
  box-shadow: ${({ theme }) =>
    theme.isDark
      ? 'none'
      : `10px -4px 15px rgba(107, 252, 243, 0.1),
    -4px 15px 15px rgba(255, 157, 245, 0.15),
    -1px -1px 10px rgba(97, 179, 255, 0.2),
    1px 1px 10px rgba(164, 146, 255, 0.2)`};
  padding: 10px;
  position: absolute;
  z-index: 4;
  min-width: 300px;

  p {
    color: ${({ theme }) => theme.colors.text.body};
    font-size: 0.95rem;
  }

  a {
    color: ${({ theme }) => (theme.isDark ? theme.colors.white : 'inherit')};
  }
`

export const TooltipTitle = styled.div`
  color: ${({ theme }) =>
    theme.isDark ? theme.colors.white : theme.colors.text.headline};
  font-size: 0.95rem;
  font-weight: bold;
  margin-bottom: 10px;
`
