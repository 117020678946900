import { DropdownWrapper } from './style'

const Dropdown = (props: DropdownProps) => {
  return (
    <DropdownWrapper
      className="dropdown"
      id={props.id}
      onChange={props.onChange}
      value={props.value}
      disabled={props.disabled}
    >
      {props.options.map((option, index) => (
        <option key={index} value={option.value} disabled={option.disabled}>
          {option.label}
        </option>
      ))}
    </DropdownWrapper>
  )
}

export default Dropdown
